import React from 'react';
import ChampionImageWithLane from "../common/ChampionImageWithLane";
import {ImageRune, ImageSpell} from "../common";
import {openModal} from "../../store/modules/modal";
import {isArena, isSwarm} from "../../function";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import ItemInfo from "./DetailTab/GameAnalytics/GameSummary/Table/TableContent/ItemInfo";


const DuoWrapper = styled.div`
  margin-left: 6px;
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .imgChamp {
    width: 42px;
    height: 42px;
  }
`;

const RuneWrapper = styled.div`
  cursor: pointer;
`;
const MatchChampInfo = ({match, myMatch}) => {
        const dispatch = useDispatch();
        const queueId = match.match_basic_dict.queue_id;
        if (isSwarm(queueId)) {
            return (
                <DuoWrapper>
                    <ItemInfo
                        isSwarm={true}
                        disable={true}
                        myMatch={myMatch}
                        item={myMatch.final_item_dict}
                    />

                </DuoWrapper>
            )

        }

        if (isArena(queueId)) {
            return (
                <DuoWrapper>
                    <ChampionImageWithLane
                        champion_id={myMatch.arena_duo_champion_id}
                        fullImage={true}
                    />
                </DuoWrapper>
            )
        }

        return (
            <>
                <dd>
                    <ImageSpell spellId={myMatch.spell_id_dict.spell_1}
                                smite={myMatch.smite}/>
                    <ImageSpell spellId={myMatch.spell_id_dict.spell_2}
                                smite={myMatch.smite}/>
                </dd>
                <dd>
                    <RuneWrapper onClick={(e) => {
                        e.stopPropagation();
                        dispatch(openModal(true, myMatch))
                    }}>
                        <ImageRune runeId={myMatch.rune_detail_dict.perk_0}/>
                        <ImageRune
                            runeId={myMatch.rune_detail_dict.perk_sub_style}/>
                    </RuneWrapper>
                </dd>
            </>);
    }
;

export default MatchChampInfo;