import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {kdaColor, scoreColor, sortChampData} from '../../function';
import TooltipWrapper from "../common/TooltipWrapper";
import ChampArrow from "./Champ/ChampArrow";
import DamageGraph from "./DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import styled, {useTheme} from "styled-components";
import ChampionImageWithLane from "../common/ChampionImageWithLane";
import {ImageLane} from "../common";

const DamageWrapper = styled.div`
  margin-inline: 16px;
  width: 70px;
`;

const Percent = styled.span`
  margin-left: 2px;
  color: ${props => props.theme.color.dark_200};
`;


const AllChampWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SmallImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-right: 6px;
`;

const ChampTable = ({
                        sort,
                        data,
                        handleSort,
                        currentOrder,
                        openId,
                        setOpenId,
                        sortOrder,
                        initialSort,
                        mostDamage,
                        leastDamage
                    }) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const currentLang = useSelector((state) => state.translation.lang);
    const [moreId, setMoreId] = useState(null);
    const {i18n} = useTranslation();

    // 상세보기
    const handleToggleDetail = (champion_id) => {

        if (openId === champion_id) {
            setOpenId(null);
            setMoreId(null);
        } else {
            setOpenId(champion_id);
        }
    };

    // 상세보기의 더보기
    const handleMoreDetail = (champion_id) => {
        const currentId = moreId;
        if (currentId === champion_id) {
            setMoreId(null);
        } else {
            setMoreId(champion_id);
        }
    };

    const calcWinRateScore = useCallback((value) => {
        if (Number(value) === 100) {
            return Number(value);
        } else {
            return value;
        }
    }, []);


    const getOpenChampionLength = () => {
        if (data[0].lane !== undefined) {
            return '190px'
        }
        return '144px'
    }

    return (
        <>
            <table className="table">
                <colgroup>
                    <col width="40px"/>
                    <col width="180px"/>
                    {data[0].lane !== undefined && <col width="56px"/>}
                    <col width="70px"/>
                    <col width="130px"/>
                    <col width="95px"/>
                    <col width="100px"/>
                    <col width="100px"/>
                    <col width="56px"/>
                    <col width="70px"/>
                    <col width="73px"/>
                    <col width="56px"/>
                    <col width="36px"/>
                </colgroup>
                <thead>
                <tr>
                    <th rowSpan="2">
                        <div className="t-cell">
                            <span className="name">#</span>
                        </div>
                    </th>
                    <th rowSpan="2" className={`sort ${currentOrder}`}
                        onClick={() => handleSort(currentLang === 'KOR' ? sortOrder?.champion_name_kr : sortOrder?.champion_name_en)}>
                        <div className="t-cell">
                            <ChampArrow
                                filterSelected={(currentLang === 'KOR' ? sortOrder?.champion_name_kr.value : sortOrder?.champion_name_en.value) === sort[0].value}
                                title={t('tabs.champions')}
                                arrow={currentLang === 'KOR' ? sortOrder?.champion_name_kr.sortType : sortOrder?.champion_name_en.sortType}
                            />
                            {/*<span className="name">{t('tabs.champions')}</span>*/}
                            {/*<span className="arrow"></span>*/}
                        </div>
                    </th>
                    {data[0].lane !== undefined && (
                        <th rowSpan="2">
                            <div className="t-cell">
                                <span className="name">{t('tabs.position')}</span>
                            </div>
                        </th>
                    )}
                    <th
                        rowSpan="2"
                        className={`sort ${currentOrder}`}
                        onClick={() => handleSort(sortOrder?.games)}
                    >
                        <div className="t-cell">
                            <ChampArrow
                                filterSelected={sortOrder?.games.value === sort[0].value}
                                title={t('tabs.games')}
                                arrow={sortOrder?.games.sortType}
                            />
                        </div>
                    </th>
                    <th
                        rowSpan="2"
                        className={`sort ${currentOrder}`}
                        onClick={() => handleSort(sortOrder.win_rate)}
                    >
                        <div className="t-cell">
                            <ChampArrow
                                filterSelected={sortOrder?.win_rate.value === sort[0].value}
                                extra={t('tabs.winrate')}
                                title={t('tabs.winlose')}
                                arrow={sortOrder?.win_rate?.sortType}
                            />
                        </div>
                    </th>
                    <th rowSpan="2" className={`sort ${currentOrder}`} onClick={() => handleSort(sortOrder.kda)}>
                        <div className="t-cell">
                            <ChampArrow
                                filterSelected={sortOrder?.kda.value === sort[0].value}
                                title={'KDA'}
                                arrow={sortOrder?.kda.sortType}
                            />
                        </div>
                    </th>

                    <th className={`sort ${currentOrder}`}
                        onClick={() => handleSort(sortOrder?.damage_dealt_per_min)}>
                        <div className={"t-cell"}>
                            <ChampArrow
                                filterSelected={sortOrder?.damage_dealt_per_min.value === sort[0].value}
                                title={t('tabs.damage')}
                                arrow={sortOrder?.damage_dealt_per_min.sortType}
                            />

                        </div>
                    </th>
                    <th className={`sort ${currentOrder}`}
                        onClick={() => handleSort(sortOrder?.damage_taken_per_min)}>
                        <div className={"t-cell"}>
                            <ChampArrow
                                filterSelected={sortOrder?.damage_taken_per_min.value === sort[0].value}
                                title={t('tabs.takenDamage')}
                                arrow={sortOrder?.damage_taken_per_min.sortType}
                            />
                        </div>
                    </th>
                    <th
                        rowSpan="2"
                        className={`sort ${currentOrder}`}
                        onClick={() => handleSort(sortOrder?.cs_per_min)}
                    >
                        <TooltipWrapper
                            tooltipText={t('tabs.csMExplain')}
                        >
                            <div className="t-cell">
                                <ChampArrow
                                    filterSelected={sortOrder?.cs_per_min.value === sort[0].value}
                                    title={t('tabs.csm')}
                                    arrow={sortOrder?.cs_per_min.sortType}
                                />
                            </div>
                        </TooltipWrapper>
                    </th>
                    <th
                        rowSpan="2"
                        className={`sort ${currentOrder}`}
                        onClick={() => handleSort(sortOrder?.gold_diff_15)}
                    >
                        <TooltipWrapper
                            tooltipText={t('tabs.gd@15Explain')}
                        >
                            <div className="t-cell">
                                <ChampArrow
                                    filterSelected={sortOrder?.gold_diff_15.value === sort[0].value}
                                    title={'GD@15'}
                                    arrow={sortOrder?.gold_diff_15.sortType}
                                />
                            </div>
                        </TooltipWrapper>
                    </th>
                    <th
                        className={`sort ${currentOrder}`}
                        onClick={() => handleSort(sortOrder?.damage_per_team)}>
                        <div className={"t-cell"}>
                            <TooltipWrapper
                                tooltipText={t('tabs.dsTooltip')}
                            >
                                <ChampArrow
                                    filterSelected={sortOrder?.damage_per_team.value === sort[0].value}
                                    title={'DMG%'}
                                    arrow={sortOrder?.damage_per_team.sortType}
                                />
                            </TooltipWrapper>
                        </div>
                    </th>
                    <th
                        className={`sort ${currentOrder}`}
                        onClick={() => handleSort(sortOrder?.cs_15)}>
                        <div className={"t-cell"}>
                            <TooltipWrapper
                                tooltipText={t('tabs.csTooltip')}
                            >
                                <ChampArrow
                                    filterSelected={sortOrder?.cs_15.value === sort[0].value}
                                    title={'CS@15'}
                                    arrow={sortOrder?.cs_15.sortType}
                                />
                            </TooltipWrapper>
                        </div>
                    </th>
                    <th>
                        <div className={"t-cell"}>
                        </div>
                    </th>
                    {/*<th rowSpan="2" className={`sort ${currentOrder}`} onClick={() => handleSort('dpm')}>*/}
                    {/*    <div className="t-cell">*/}
                    {/*    </div>*/}
                    {/*</th>*/}
                    {/*    <th*/}
                    {/*        rowSpan="2"*/}
                    {/*        className={`sort ${currentOrder}`}*/}
                    {/*        onClick={() => handleSort('kill_point_per_g')}*/}
                    {/*    >*/}
                    {/*        <div className="t-cell">*/}
                    {/*            <ChampArrow*/}
                    {/*                title={t('tabs.kp')}*/}
                    {/*                arrow={sortOrder?.kill_point_per_g.sortType}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </th>*/}
                    {/*    <th rowSpan="2">*/}
                    {/*        <div className="t-cell">*/}
                    {/*            <span className="name">&nbsp;</span>*/}
                    {/*        </div>*/}
                    {/*    </th>*/}
                    {/*</tr>*/}
                    {/*<tr className="bottom">*/}
                    {/*    <th*/}
                    {/*        className={`sort ${currentOrder}`}*/}
                    {/*        onClick={() => handleSort('d_score_win_per_g')}*/}
                    {/*    >*/}
                    {/*        <div className="t-cell">*/}
                    {/*            <ChampArrow*/}
                    {/*                title={t('champions.filter.win')}*/}
                    {/*                arrow={sortOrder?.d_score_win_per_g.sortType}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </th>*/}
                    {/*    <th*/}
                    {/*        className={`sort ${currentOrder}`}*/}
                    {/*        onClick={() => handleSort('d_score_loss_per_g')}*/}
                    {/*    >*/}
                    {/*        <div className="t-cell">*/}
                    {/*            <ChampArrow*/}
                    {/*                title={t('champions.filter.lose')}*/}
                    {/*                arrow={sortOrder?.d_score_loss_per_g.sortType}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </th>*/}
                    {/*    <th className={`sort ${currentOrder}`} onClick={() => handleSort('d_score_per_g')}>*/}
                    {/*        <div className="t-cell">*/}
                    {/*            <ChampArrow*/}
                    {/*                title={t('champions.filter.avg')}*/}
                    {/*                arrow={sortOrder?.d_score_per_g.sortType}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </th>*/}
                </tr>
                </thead>
                <tbody>
                {data.map((row, index) => {
                    return (
                        <React.Fragment key={index}>
                            <tr className={openId === row.champion_id ? 'open' : 'close'}
                                onClick={() => handleToggleDetail(row.champion_id)}>
                                <td>
                                    <span className="normal">{row.rank}</span>
                                </td>
                                <td>
                                    <div className="champ">
                                        {row?.champion_id ? <ChampionImageWithLane champion_id={row.champion_id}
                                                                                   enableChampName={true}/> :
                                            <AllChampWrapper>
                                                <SmallImageBox>
                                                    <img
                                                        src={`${theme.icon.path}/icon_s_position_all.svg`}
                                                        width={14}
                                                        height={14}
                                                        alt={''}
                                                    />
                                                </SmallImageBox>
                                                <span>{t('champions.allChamp')}</span>
                                            </AllChampWrapper>}
                                    </div>
                                </td>
                                {data[0].lane !== undefined && (
                                    <td>
                                        <ImageLane lane={row.lane}/>
                                    </td>
                                )}
                                <td>
                                    <span className="bold">{row.games}</span>
                                </td>
                                <td>
                                    <div className="winlose">
                                        <div className="graph">
                          <span className="win" style={{width: `${row.win_rate}%`}}>
                            <i>{row.wins}</i>
                          </span>
                                            <span className="lose" style={{width: `${100 - row.win_rate}%`}}>
                            <i>{row.losses}</i>
                          </span>
                                        </div>
                                        <div className="winrate">
                                                <span
                                                    className="bold">{scoreColor(calcWinRateScore(row.win_rate.toFixed(1)))}</span>%
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="kda">
                                        <span className="bold">{kdaColor(row.kda.toFixed(2))}</span>
                                        <p>
                                            ({row.kills.toFixed(1)} / {row.deaths.toFixed(1)} / {row.assists.toFixed(1)})
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <DamageWrapper>
                                        <DamageGraph
                                            bgColor={theme.color.gray_100}
                                            color={theme.color.dark_200}
                                            barColor={'#EF4D8A'}
                                            dmg={row.damage_dealt_per_min.toFixed(0)}
                                            total={mostDamage.toFixed(0)}
                                        />
                                    </DamageWrapper>
                                </td>
                                <td>
                                    <DamageWrapper>
                                        <DamageGraph
                                            bgColor={theme.color.gray_100}
                                            color={theme.color.dark_200}
                                            barColor={'#7C8698'}
                                            dmg={row.damage_taken_per_min.toFixed(0)}
                                            total={leastDamage.toFixed(0)}
                                        />
                                    </DamageWrapper>
                                </td>
                                <td>
                                    <span className="normal">{row.cs_per_min.toFixed(1)}</span>
                                </td>
                                <td>
                                    <span className="normal">{row.gold_diff_15.toFixed(0)}</span>
                                </td>
                                <td>
                                    <span className="normal">{row.damage_per_team.toFixed(1)}<Percent>%</Percent></span>
                                </td>
                                <td>
                                    <span className="normal">{row.cs_15.toFixed(0)}</span>
                                </td>
                                <td>
                                    <button className="arrow"
                                            onClick={() => handleToggleDetail(row.champion_id)}>
                                        <img src={`${theme.icon.path}/icon_arrow_b.svg`} alt={t('summoner.more')}/>
                                    </button>
                                </td>
                            </tr>
                            {openId === row.champion_id && (
                                <tr className="detail">
                                    <td colSpan="20">
                                        <div className={`vs_champ ${data[0].lane === undefined ? "" : "lane"}`}>
                                            <div
                                                className={`list_wrap ${moreId === row.champion_id ? 'open' : 'close'}`}>
                                                <ul className="list">
                                                    {sortChampData(row.stats_by_enemy, initialSort).map(
                                                        (item, idx) => {
                                                            return (
                                                                <li key={idx}>
                                                                    <div className="td" style={{width: '40px'}}>
                                                                        <div className="vs">VS</div>
                                                                    </div>
                                                                    <div className="td"
                                                                         style={{width: getOpenChampionLength()}}>
                                                                        <div className="champ">
                                                                            <ChampionImageWithLane
                                                                                champion_id={item.champion_id}
                                                                                enableChampName={true}/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="td" style={{width: '70px'}}>
                                                                                <span
                                                                                    className="bold">{item.games}</span>
                                                                    </div>
                                                                    <div className="td"
                                                                         style={{width: '130px'}}>
                                                                        <div className="winlose">
                                                                            <div className="graph">
                                            <span
                                                className="win"
                                                style={{width: `${item.win_rate}%`}}
                                            >
                                              <i>{item.wins}</i>
                                            </span>
                                                                                <span
                                                                                    className="lose"
                                                                                    style={{width: `${100 - item.win_rate}%`}}
                                                                                >
                                              <i>{item.losses}</i>
                                            </span>
                                                                            </div>
                                                                            <div className="winrate">
                                                                                    <span
                                                                                        className="bold">{scoreColor(item.win_rate.toFixed(1))}</span>
                                                                                %
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="td"
                                                                         style={{width: data[0].lane === undefined ? '95px' : '90px'}}>
                                                                        <div className="kda">
                                                                                <span
                                                                                    className="bold">{kdaColor(item.kda)}</span>
                                                                            <p>
                                                                                ({item.kills.toFixed(1)} / {item.deaths.toFixed(1)} / {item.assists.toFixed(1)})
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="td" style={{width: '100px'}}>
                                                                        <DamageWrapper>
                                                                            <DamageGraph
                                                                                bgColor={theme.color.gray_100}
                                                                                color={theme.color.font_74}
                                                                                barColor={'#EF4D8A'}
                                                                                dmg={item.damage_dealt_per_min.toFixed(0)}
                                                                                total={mostDamage.toFixed(0)}
                                                                            />
                                                                        </DamageWrapper>
                                                                    </div>
                                                                    <div className="td"
                                                                         style={{width: ['ko', 'ko-KR'].includes(i18n.language) ? '100px' : '116px'}}>
                                                                        <DamageWrapper>
                                                                            <DamageGraph
                                                                                bgColor={theme.color.gray_100}
                                                                                color={theme.color.font_74}
                                                                                barColor={'#7C8698'}
                                                                                dmg={item.damage_taken_per_min.toFixed(0)}
                                                                                total={mostDamage.toFixed(0)}
                                                                            />
                                                                        </DamageWrapper>
                                                                    </div>
                                                                    <div className="td" style={{width: '56px'}}>
                                                                            <span
                                                                                className="normal">{item.cs_per_min.toFixed(1)}</span>
                                                                    </div>
                                                                    <div className="td" style={{width: '70px'}}>
                                        <span className="normal">
                                          {item.gold_diff_15.toFixed(0)}
                                        </span>
                                                                    </div>
                                                                    <div className="td"
                                                                         style={{width: '73px'}}>
                                                                            <span
                                                                                className="normal">{item.damage_per_team.toFixed(1)}%</span>
                                                                    </div>
                                                                    <div className="td" style={{width: '56px'}}>
                                                                            <span
                                                                                className="normal">{item.cs_15.toFixed(0)}</span>
                                                                    </div>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                                {row.stats_by_enemy.length > 10 ? (
                                                    <button className="list_more"
                                                            onClick={() => handleMoreDetail(row.champion_id)}>
                                                        {moreId === row.champion_id ? t('summoner.close') : t('summoner.more')}
                                                    </button>
                                                ) : null}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    );
                })}
                </tbody>
            </table>
        </>
    );
};

export default ChampTable;
