import React, {useCallback} from 'react';
import styled from "styled-components";
import {getRegionColor} from "../../../../function";


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.isDisabled ? "auto" : "pointer"};
  background-color: ${props => {
    return getRegionColor(props.region);
  }};
  border-radius: ${props => props.borderRadius || "100px"};
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
  opacity: ${props => props.isDisabled ? "0.3" : "1"};
  box-shadow: ${props => {
    const color = getRegionColor(props.region);
    let shadow = `0 0 10px 0px ${color};`;
    if (props.selected && props.enableShadow) {
      return shadow
    }
    return 0;
  }};

  &:hover {
    box-shadow: ${props => {
      const color = getRegionColor(props.region);
      let shadow = `0 0 10px 0px ${color};`;
      if (props.disableHover) {
        return 0;
      }
      
      if (!props.isDisabled) {
        return shadow;
      }
      return 0;
    }};
  }
`;

const TitleText = styled.span`
  font-size: ${props => {
    let size = '12px';
    if (props.fontSize) size = props.fontSize;
    return size;
  }};
  font-weight: ${props => {
    let weight = 'normal';
    if (props.selected) weight = 'bold';
    if (props.customWeight) weight = 'normal';
    return weight;
  }};
  color: #fff;
`;

const RegionTag = ({
                       title,
                       isDisabled,
                       onClick,
                       selected,
                       width,
                       height,
                       borderRadius,
                       customWeight,
                       fontSize,
                       enableShadow,
                       disableHover,
                   }) => {
    const onClickWrapper = useCallback(() => {
        if (!isDisabled && typeof title === 'string' && onClick) {
            onClick(title);
        }
    }, [isDisabled, onClick]);
    return (
        <Wrapper region={title} className={"regionTag"} isDisabled={isDisabled} selected={selected}
                 onClick={onClickWrapper}
                 width={width}
                 height={height}
                 borderRadius={borderRadius}
                 enableShadow={enableShadow}
                 disableHover={disableHover}
        >
            <TitleText selected={selected} customWeight={customWeight} fontSize={fontSize}>{title}</TitleText>
        </Wrapper>
    );
};

export default RegionTag;
