import styled from 'styled-components';

export const MultiContainer = styled.div`
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  min-height: 90vh;
  overflow: hidden;

  .center {
    display: flex;
    flex: 1;
  }

  .contentCenter {
    display: flex;
    flex: 1;
  }


  .buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 36px;
      background-color: ${props => props.theme.color.site_main_color};
      border: 1px solid ${props => props.theme.color.default_border_color};
      margin-left: 8px;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 700;
      color: ${props => props.theme.color.dark_200};

      &:hover {
        background-color: ${props => props.theme.color.white_200};
      }

      &::before {
        display: inline-block;
        margin-right: 8px;
      }

      &.ingame {
        &::before {
          content: 'LIVE';
          padding: 2px 2px 3px;
          font-size: 13px;
          color: #fff;
          font-weight: bold;
          letter-spacing: 0.08em;
          background: #eb5b5b;
          border: 1px solid #8f3535;
          border-radius: 4px;
        }
      }

      &.share {
        &::before {
          content: 'copy';
          width: 21px;
          height: 21px;
          background: ${props => `url('${props.theme.icon.path}/ico_copy_link.svg') no-repeat center`};
          text-indent: -9999px;
        }
      }
    }

    .share_tooltip {
      position: absolute;
      top: -15px;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 50px;
      background-color: rgba(0, 0, 0, 0.85);
      border-radius: 8px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      color: #65cbff;
      transform: translateX(-50%);
    }
  }

  .multi_top {
    border: 1px solid ${props => props.theme.color.default_border_color};
    border-radius: 6px;
    overflow: hidden;
    margin-top: 10px;


    .info {
      margin-left: 16px;

      .box {
        width: 176px;
        background-color: ${props => props.theme.color.site_main_color};
        border: 1px solid ${props => props.theme.color.default_border_color};
      }

      h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        background: transparent linear-gradient(98deg, #34b8a5 0%, #4174c8 100%);
        font-size: 14px;
        color: ${props => props.theme.color.site_main_color};
      }

      .bg {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        text-align: center;

        p {
          font-size: 38px;
          font-weight: 700;
          color: #008e6c;

          span {
            display: inline-block;
            margin-left: 8px;
            font-size: 24px;
          }
        }
      }
    }


  }

  .multi_bottom {
    display: flex;
    justify-content: flex-start;
    //max-width: 1700px;
  }

  .how_to_use {
    width: 100%;
    height: fit-content;
    background-color: ${props => props.theme.color.site_main_color};
    border: 1px solid ${props => props.theme.color.default_border_color};
    border-radius: 6px;
    overflow: hidden;

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      background: ${props => props.theme.color.site_main_color};
      font-size: 18px;
      font-weight: 700;
      color: ${props => props.theme.color.dark_100};
      border-bottom: 1px solid ${props => props.theme.color.default_border_color};
    }

    .box {

      padding: 26px 22px;

      p {
        font-size: 16px;
        color: ${props => props.theme.color.dark_100};
        text-align: center;

        span {
          font-weight: 700;
        }
      }

      .imgs {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        max-width: 100%;

        .picks,
        .browser {
          /* height: 260px; */
        }
      }
    }
  }

  .imgEmblem {
    width: 55px;
  }

  .imgChamp {
    width: 42px;
  }

  .imgDiv {
    border-radius: 7px;
  }
`;

export const MultiSearchContainer = styled.div`
  background-color: ${props => props.theme.color.site_main_color};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;

  textarea {
    width: 100%;
    height: 100%;
    min-height: 178px;
    padding: 4px 13px;
    resize: none;
    color: ${props => props.theme.color.dark_100};
    border: 1px solid ${props => props.theme.color.default_border_color};
    overflow-y: auto;
    font: inherit;
    font-size: 12px;
    line-height: 1.4;
    background-color: ${props => props.theme.color.white_201};
    border-radius: 6px;

    &::placeholder {
      color: ${props => props.theme.color.dark_100};
      opacity: 1;
    }

    :focus::-webkit-input-placeholder {
      color: transparent;
    }

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #b8cddc;
    }

    ::-webkit-scrollbar-track {
      background-color: #dbe8ff;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 40px;
    background: ${props => props.theme.color.green_100};
    border-radius: 6px;

    i {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url('/images/ico_input_serach_big_w.svg') no-repeat center;
      font-size: 0;
    }
  }
`;

export const MultiListContainer = styled.div`
  width: 1078px;
  background-color: ${props => props.theme.color.site_main_color};
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  font-size: 12px;
  height: fit-content;

  .row {
    position: relative;
    display: flex;

    &:last-of-type {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

  }

  .col {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex_column1 {
    display: flex;
    flex-direction: column;
    width: 234px;
    margin-left: 15px;
  }

  .flex_column2 {
    width: 100px;

  }

  .flex_column3 {
    display: flex;
    flex-direction: row;
    width: 510px;
  }

  .flex_column4 {
    width: 217px;
  }

  .flex_full {
    width: 100%;
    font-size: 18px;
  }

  .t_head {
    background: ${props => props.theme.color.site_main_color};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .col {
      height: 35px;
      font-weight: 500;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 1px;
        height: 8px;
        background-color: ${props => props.theme.color.default_border_color};
      }

      &:first-of-type {
        &::after {
          display: none;
        }
      }
    }
  }

  .t_body {
    width: 1077px;


    .row {
      border-top: 1px solid ${props => props.theme.color.default_border_color};


      &.complete {
        &::after {
          display: none;
        }
      }

      &.incomplete {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9999;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }

      .loading {
        position: absolute;
        z-index: 30;

        .dank-ass-loader {
          margin-top: 0;
        }
      }
    }

    .row + .row {
      border-top: 1px solid ${props => props.theme.color.gray_200};
    }

    .col {
      height: 130px;
    }

    .flex_column1 {
      justify-content: flex-start;

      .tags {
        display: flex;
        align-content: center;

        & > span {
          display: inline-block;
          text-align: center;
          width: 38px;
          height: 18px;
          font-size: 11px;
          color: ${props => props.theme.color.site_main_color};
          border-radius: 3px;
          line-height: 16px;

          & + span {
            margin-left: 5px;
          }

          &.win {
            background-color: #15b95d;
            border: 1px solid #2a9454;
          }

          &.lose {
            background-color: #e37e74;
            border: 1px solid #a85c54;
          }

          &.w4 {
            width: 46px;
          }
        }
      }

      .promos {
        max-width: 85px;

        span {
          width: 13px;
          height: 13px;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
          font-size: 0;
          text-indent: -9999px;
        }
      }

      .icons {
        width: 60px;
        text-align: center;

        p {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          padding: 2px 5px;
          background-color: ${props => props.theme.color.white_201};
          border-radius: 4px;

          span {
            display: inline-block;
            margin-left: 3px;
            font-size: 11px;
            /* text-transform: uppercase; */
          }
        }

        .duo {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 37px;
          height: 17px;
          background-color: #90d489;
          border: 1px solid #779f73;
          border-radius: 3px;
          font-size: 11px;
          font-weight: 700;
        }
      }

      .texts {
        position: relative;
        margin-left: 14px;
        line-height: 20px;
        display: flex;
        flex-direction: column;

        &.tag_on {
        }

        &.tag_off {
        }

        a {
          display: block;
          width: 130px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: break-word;
          font-size: 14px;
          font-weight: bold;

          &:hover {
            text-decoration: underline;
          }
        }

        dl {
          display: flex;
          align-items: center;
        }

        .t1 {
          font-weight: 500;

          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            color: ${props => props.theme.color.dark_200};

            span {
              display: inline-block;
              margin-right: 4px;
              font-size: 14px;
              font-weight: 700;
            }
          }

          dd {
            margin-left: 3px;
          }
        }

        .t2 {
          font-weight: 500;
          font-size: 10px;
          color: ${props => props.theme.color.dark_100};

          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: ${props => props.theme.color.dark_200};
            margin-right: 5px;

            span {
              display: inline-block;
              margin-right: 2px;
              font-size: 14px;
              font-weight: 700;
              color: #259a7e;
            }
          }

          dd {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;
            font-size: 12px;
            color: ${props => props.theme.color.dark_200};

            span {
              display: inline-block;
              margin-right: 2px;
            }
          }
        }
      }
    }

    .flex_column2 {
      text-align: center;

      .aiscore {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 15px;

        p {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          color: ${props => props.theme.color.dark_200};

          span {
            display: block;
            margin-top: 6px;
            font-size: 20px;
            font-weight: 700;
          }
        }

        strong {
          display: block;
          margin-left: 8px;

          span {
            font-size: 32px;
            font-weight: 700;
          }
        }
      }
    }

    .flex_column3 {
      padding: 0 7px;

      .match_list {
        display: flex;
        width: 100%;
      }

      .match_item {
        position: relative;
      }

      .match_item + .match_item {
        margin-left: 4px;
      }

      .box {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 46px;
        padding: 4px;
        text-align: center;
        border: 1px solid ${props => props.theme.color.default_border_color};

        border-radius: 4px;

        &:hover {
          padding: 3px;
          border-width: 2px;
        }

        &.win {
          background-color: ${props => props.theme.color.blue_300};

          &:hover {
            border-color: ${props => props.theme.color.blue_100};
            background-color: ${props => props.theme.color.blue_200};
          }
        }

        &.lose {
          background-color: ${props => props.theme.color.red_400};

          &:hover {
            border-color: ${props => props.theme.color.red_100};
            background-color: ${props => props.theme.color.red_200};
          }
        }

        .champ {
          position: relative;

          .imgLane {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: rgba(0,0,0,0.6);
          }
        }

        .score {
          margin: 4px 0;
          font-size: 18px;
          font-weight: 700;
        }

        .kda {
          margin-bottom: 3px;

          .perfect {
            font-size: 11px;
          }
        }

        .rating {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 12px;
            height: 9px;
            margin-right: 3px;
            margin-top: 1px;
          }

          .away {
            color: ${props => props.theme.color.red_300};
          }
        }
      }

      .time {
        margin-top: 5px;
        font-size: 12px;
        color: ${props => props.theme.color.dark_200};
        letter-spacing: -0.01em;
        text-align: center;
      }
    }

    .flex_column4:hover {
      background-color: ${props => props.theme.color.white_200};
    }

    .flex_column4:last-of-type:hover {
      border-bottom-right-radius: 6px;
    }

    .flex_column4 {
      cursor: pointer;

      .float {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;

        &.close {
          .champ_list {
            li:nth-child(n+6) {
              display: none;
            }

            max-height: 130px;
          }
        }

        &.open {
          z-index: 20;
          background-color: ${props => props.theme.color.green_200};

          .champ_list {
            max-height: 100%;
            z-index: 22;
          }

          .more button img {
            transform: rotate(180deg);
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: calc(100% + 2px);
            height: 100%;
            border: 1px solid ${props => props.theme.color.green_100};
            box-sizing: border-box;
          }
        }
      }

      .champ_list {
        min-width: 190px;
        max-width: 190px;
        width: 100%;
        min-height: 130px;
        height: 100%;
        padding: 6px;
      }

      .champ_item {
        display: flex;
        align-items: center;
        text-align: right;

        & + .champ_item {
          margin-top: 5px;
        }
      }

      .games {
        font-weight: 500;
        width: 30px;
        margin-left: 8px;
      }

      .winrate {
        font-weight: 500;
        text-align: center;
        width: 58px;
        margin-left: 5px;
      }

      .kda {
        white-space: nowrap;
        font-weight: 500;
        margin-left: 5px;
      }

      .score {
        //width: 30px;
        //margin-left: 4px;
        //font-weight: 700;
      }

      .more {
        position: relative;
        z-index: 20;
        width: 26px;

        button {
          position: relative;
          width: 100%;
          height: 100%;

          img {
            position: absolute;
            bottom: 14px;
            left: 50%;
            margin-left: -7px;
            width: 14px;
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.08);
        }
      }

      .imgChamp {
        width: 26px;
      }

      .imgDiv {
        border-radius: 3px;
      }
    }
  }
`;
