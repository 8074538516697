import React from 'react';
import styled from "styled-components";

const Wrapper = styled.span`
  font-size: 20px;
  width: 40px;
  text-align: left;
  font-weight: bold;
  color: ${props => {
    let color = props.theme.color.gray_400;
    if (props.value >= 75) {
      color = props.theme.color.yellow_100;
    } else if (props.value >= 50) {
      color = '#259A7E';
    } else if (props.value >= 25) {
      color = '#DE5353';
    }
    return color;
  }}
`;
const DifferentScoreColor = ({value}) => {
    return (
        <Wrapper value={value}>
            {value}
        </Wrapper>
    );
};

export default DifferentScoreColor;