import React, {useCallback, useEffect, useState} from 'react';
import LPChartBox from "../../../summoner/LPChartBox";
import {getLPScore} from "../../../../function";
import _ from "lodash";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 304px;
  height: 231px;
  border-top: 1px solid ${props => props.theme.color.default_border_color};;
  padding: 10px;
`;


const SummonerMatchesLPChart = ({chartKey}) => {
    const {t} = useTranslation();
    const rankDict = useSelector((state) => state.summoner.summoner)?.season_tier_info_dict;
    const currentLang = useSelector((state) => state.translation.lang);
    const tierChart = useSelector((state) => state.summoner.tierChart);
    const getLPChartDone = useSelector((state) => state.summoner.getLPChartDone);
    const [chart,setChart] = useState({});
    const summoner = useSelector((state) => state.summoner);
    const currentTierCut = summoner.tierCut

    const isToday = useCallback((timestamp) => {
        if (typeof timestamp === 'number') {
            const multiplyTime = timestamp * 1000;
            const time = new Date(multiplyTime).getTime();
            //1660070056000
            //1660094662488
            const now = Date.now();
            const zeroHour = new Date(now);
            zeroHour.setHours(0, 0, 0, 0);
            if (time > zeroHour.getTime() && time < now) {
                return true
            } else {
                return false;
            }
        }
    }, [])

    const getChartOptions = useCallback(
        (data) => {
            let originalArr = []; // 원래 data값을 배열로
            let calculatedArr = []; // 계산된 lp 값
            let dateArr = []; // xAxis categories 값
            let tierArr = []; // tier, order 배열
            let grandMasterValue = 2800 + currentTierCut?.grandmaster_cut_off;
            let challengerValue = grandMasterValue + currentTierCut?.challenger_cut_off;





            // for (const obj in data) {
            //
            //     if (data[obj].tier === "GRANDMASTER") {
            //     }
            //     if (data[obj].tier === "CHALLENGER") {
            //     }
            // }


            for (const i in data) {
                originalArr.push(data[i]);
                // 티어별 order값
                let tierOrder = 0;
                if (data[i].tier === 'IRON') tierOrder = 1;
                else if (data[i].tier === 'BRONZE') tierOrder = 2;
                else if (data[i].tier === 'SILVER') tierOrder = 3;
                else if (data[i].tier === 'GOLD') tierOrder = 4;
                else if (data[i].tier === 'PLATINUM') tierOrder = 5;
                else if (data[i].tier === 'EMERALD') tierOrder = 6;
                else if (data[i].tier === 'DIAMOND') tierOrder = 7;
                else if (data[i].tier === 'MASTER') tierOrder = 8;
                else if (data[i].tier === 'GRANDMASTER') tierOrder = 9;
                else if (data[i].tier === 'CHALLENGER') tierOrder = 10;


                tierArr.push({
                    tier: data[i].tier,
                    order: tierOrder,
                });

                // 계산된 lp로 입력
                calculatedArr.push(getLPScore(data[i].tier, data[i].division, data[i].league_points, grandMasterValue, challengerValue));

                // xAxis categories (날짜데이터)
                // let idxDate = new Date(i * 1000);
                // let idxDate = getGameTime(i, currentLang);
                dateArr.push(data[i].timestamp);
            }

            // calculatedArr로 티어별 최소,최대값 구하기

            // 객체배열 중복제거
            tierArr = _.uniqBy(tierArr, 'tier');
            tierArr.sort(function (a, b) {
                // order 순으로 정렬
                return a.order - b.order;
            });


            let yTickPositions = {
                GRANDMASTER: grandMasterValue,
                CHALLENGER: challengerValue,
            }

            if(yTickPositions.GRANDMASTER === 2800 && yTickPositions.CHALLENGER === 2800) {
                yTickPositions.GRANDMASTER = 3200;
                yTickPositions.CHALLENGER = 3600;
            }


            // 티어별 yAxis tickPositions 값 넣기


            // yTickPositions 앞뒤로 400 추가 (왜 하는거지?)
            // let minTP = yTickPositions[0];
            // let maxTP = yTickPositions[yTickPositions.length - 1];
            // if (minTP !== 0) yTickPositions.unshift(minTP - 400);
            // if (maxTP < 2400) yTickPositions.push(maxTP + 400);
            // else yTickPositions.push(maxTP + 1000);

            // 내림차순 정렬


            return {originalArr, calculatedArr, dateArr, yTickPositions};
        },
        [currentLang]
    )

    function setRankData(key) {
        if (tierChart[key]) {
            const array = [...tierChart[key]];
            if (array.length > 0) {
                if (array[array.length - 1]?.timestamp) {
                    if (isToday(array[array.length - 1].timestamp)) {
                        array[array.length - 1] = {
                            timestamp: Date.now() / 1000,
                            tier: rankDict?.[key]?.tier,
                            division: rankDict?.[key]?.division,
                            league_points: rankDict?.[key]?.league_points,
                        }
                    }
                    return getChartOptions([...array]);
                }
            } else {
                return {};
            }
        }
        return [];
    }

    useEffect(() => {
        if(getLPChartDone) {
            if (tierChart && tierChart !== {}) {
                setChart(setRankData(chartKey))
            }
        }
    },[getLPChartDone,tierChart]);


    return (
        <Wrapper>
            <LPChartBox title={t('summoner.graph')} chart={chart} loading={getLPChartDone}/>
        </Wrapper>
    );
};

export default SummonerMatchesLPChart;