import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ChartMulti from "../charts/ChartMulti";
import {getLPScore} from "../../function";
import _ from "lodash";
import styled from "styled-components";
import {Loading} from "../common";
import useTierCutAPI from "../../common/hooks/useTierCutAPI";

const ChartWrapper = styled.div`
  margin-top: 10px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  min-height: 120px;
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;
  overflow: hidden;
`;
const ChartHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  padding-left: 15px;
  padding-block: 13px;
  margin-bottom: 15px;
`;
const ChartHeaderText = styled.span`
  font-weight: bold;
  font-size: 12px;
`;
const RecentDaysText = styled.span`
  font-size: 12px;
  font-weight: normal;
  color: ${props => props.theme.color.dark_200};
  margin-left: 5px;
`;


const MultiChart = () => {
    const {t} = useTranslation()
    const {
        multiList,
        multiLPChart,
        getMultiListDone,
        getMultiLPChartDone,
    } = useSelector((state) => state.summoner);

    const region = useSelector((state) => state.region.result);
    const {tierCut} = useTierCutAPI(region)

    const getArrChartOptions = (arr, lpArr) => {
        if (Array.isArray(lpArr) && Array.isArray(arr) && arr.length > 0) {
            const result = arr.map((data, index) => {
                if (lpArr[index]?.ranked_solo_5x5) {
                    return {
                        ...getChartOptions(lpArr[index].ranked_solo_5x5),
                        summoner: data.summoner,
                    };
                } else {
                    return {
                        calculatedArr: [],
                        originalArr: [],
                        dateArr: [],
                    }
                }

            });

            const longestLength = Math.max.apply(null, result.map((data) => data && data.calculatedArr.length));


            const answer = result.map((data) => {
                return {
                    ...data,
                    multiCalArr: data.calculatedArr.length === longestLength ? data.calculatedArr :
                        new Array(longestLength).fill('').map((item, index) => {
                            if (longestLength - index <= data.calculatedArr.length) {
                                const result = data.calculatedArr[(data.calculatedArr.length) - (longestLength - index)];
                                return result ? result : "";
                            } else {
                                return "";
                            }
                        }),
                    multiOriginalArr: data.originalArr.length === longestLength ? data.originalArr :
                        new Array(longestLength).fill('').map((item, index) => {

                            if (longestLength - index <= data.originalArr.length) {

                                const result = data.originalArr[(data.originalArr.length) - (longestLength - index)];
                                return result ? result : 0;
                            } else {
                                return 0;
                            }
                        }),
                }
            });
            return answer;
        }

    }


    const getChartOptions = (data) => {
        let originalArr = []; // 원래 data값을 배열로
        let calculatedArr = []; // 계산된 lp 값
        let dateArr = []; // xAxis categories 값
        let tierArr = []; // tier, order 배열
        let grandMasterValue = 2800 + tierCut?.data?.grandmaster_cut_off;
        let challengerValue = grandMasterValue + tierCut?.data?.challenger_cut_off;


        // for (const obj in data) {
        //
        //     if (data[obj].tier === "GRANDMASTER") {
        //         grandMasterValue = Math.min(grandMasterValue, data[obj].league_points + 2800);
        //         grandMaxValue = Math.max(grandMaxValue, data[obj].league_points + 2800);
        //     }
        //     if (data[obj].tier === "CHALLENGER") {
        //         challengerValue = Math.min(challengerValue, data[obj].league_points + 3100);
        //         challengerMaxvalue = Math.max(challengerMaxvalue, data[obj].league_points + 2800);
        //     }
        // }


        for (const i in data) {
            originalArr.push(data[i]);
            // 티어별 order값
            let tierOrder = 0;
            if (data[i].tier === 'IRON') tierOrder = 1;
            else if (data[i].tier === 'BRONZE') tierOrder = 2;
            else if (data[i].tier === 'SILVER') tierOrder = 3;
            else if (data[i].tier === 'GOLD') tierOrder = 4;
            else if (data[i].tier === 'PLATINUM') tierOrder = 5;
            else if (data[i].tier === 'DIAMOND') tierOrder = 6;
            else if (data[i].tier === 'MASTER') tierOrder = 7;
            else if (data[i].tier === 'GRANDMASTER') tierOrder = 8;
            else if (data[i].tier === 'CHALLENGER') tierOrder = 9;


            tierArr.push({
                tier: data[i].tier,
                order: tierOrder,
            });

            // 계산된 lp로 입력
            calculatedArr.push(getLPScore(data[i].tier, data[i].division, data[i].league_points, grandMasterValue, challengerValue));

            // xAxis categories (날짜데이터)
            // let idxDate = new Date(i * 1000);
            // let idxDate = getGameTime(i, currentLang);
            // let idxDate = getGameTime(i, currentLang);
            dateArr.push(data[i].timestamp);
        }

        // calculatedArr로 티어별 최소,최대값 구하기
        // let yTickPositions = [minVal, maxVal]; // yAxis tickPositions 값

        // 객체배열 중복제거
        tierArr = _.uniqBy(tierArr, 'tier');
        tierArr.sort(function (a, b) {
            // order 순으로 정렬
            return a.order - b.order;
        });


        let yTickPositions = {
            GRANDMASTER: grandMasterValue,
            CHALLENGER: challengerValue,
        }

        // 티어별 yAxis tickPositions 값 넣기


        // yTickPositions 앞뒤로 400 추가 (왜 하는거지?)
        // let minTP = yTickPositions[0];
        // let maxTP = yTickPositions[yTickPositions.length - 1];
        // if (minTP !== 0) yTickPositions.unshift(minTP - 400);
        // if (maxTP < 2400) yTickPositions.push(maxTP + 400);
        // else yTickPositions.push(maxTP + 1000);

        // 내림차순 정렬


        return {originalArr, calculatedArr, dateArr, yTickPositions};
    };


    return (
        <ChartWrapper>
            <ChartHeaderWrapper>
                <ChartHeaderText>LP {t('summoner.graph')}
                    <RecentDaysText>({t('summoner.recent')} 60{t('summoner.day')})</RecentDaysText></ChartHeaderText>
            </ChartHeaderWrapper>
            {(getMultiListDone && getMultiLPChartDone) ?
                <ChartMulti
                    data={getArrChartOptions(multiList, multiLPChart)}
                /> :
                <Loading/>
            }
        </ChartWrapper>
    );
};

export default MultiChart;