import React, {useCallback} from 'react';
import InfluInfo from "../../../summoner/Profile/InfluInfo";
import styled, {useTheme} from "styled-components";
import TierTag from "../../../common/TierTag";

const ContentBox = styled.div`


`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  padding: 15px;
  background-color: ${props => {
    let color = 'transparent';
    if (props.selected) color = props.theme.color.white_200;
    return color;
  }};
  border: 1px solid ${props => {
    let color = 'transparent';
    return color;
  }};

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }



`;

const Bar = styled.div`
  width: 32px;
  height: 3px;
  background-color: ${props => props.barColor}
`;

const ContentWrapper = styled.div`
  padding-left: 14px;
`;

const StreamerInfoWrapper = styled.div`
  margin-bottom: 4px;
`;

const TierWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;


const TierBadge = styled.span`
  color: ${props => props.theme.color.site_main_color};

  .tier_badge {
    border-radius: 5px;
    display: flex;
    font-size: 11px !important;
    width: 24px;
    height: 14px;
    justify-content: center;
    align-items: center;
  }
`;

const SummonerName = styled.span`
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  margin-left: 3px;
`;


const ChartMultiLegend = ({selected, index, onClick, tier, summonerName, barColor, influData, division}) => {
    const theme = useTheme();
    const onClickWrapper = useCallback(() => {
        if (typeof onClick === 'function') {
            if (selected) {
                onClick(99);
            } else {
                onClick(index);
            }
        }
    }, [selected, index, onClick]);

    return (
            <Wrapper onClick={onClickWrapper} selected={selected} className={theme.color.theme}>
                <Bar index={index} barColor={barColor}/>
                <ContentWrapper>
                    {influData?.status &&
                        <StreamerInfoWrapper>
                            <InfluInfo
                                showLinks={false}
                                influData={influData}
                                maxWidthText={100}
                            />
                        </StreamerInfoWrapper>}
                    <TierWrapper>
                        <TierBadge>
                            <TierTag
                                fontSize={'11px'}
                                padding={'4px 4px'}
                                tier={tier}
                                division={division}
                            />
                        </TierBadge>
                        <SummonerName>{summonerName}</SummonerName>
                    </TierWrapper>
                </ContentWrapper>
            </Wrapper>
    );
};

export default ChartMultiLegend;
