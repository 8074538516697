import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {cutLaneName, getShortTime, kdaColor, scoreColor, tierText} from '../../function';
import styled, {useTheme} from "styled-components";
import SummonerDict from "../common/SummonerDict";
import SummonerTags from "../summoner/SummonerTags";
import MultiItemScore from "./MultiItem/MultiItemScore";
import InfluInfo from "../summoner/Profile/InfluInfo";
import ChampionImageWithLane from "../common/ChampionImageWithLane";
import SummonerTag from "../common/SummonerTag";
import {ImageLane, Loading} from "../common";
import DifferentScoreColor from "../common/DifferentScoreColor";
import {FlexBox} from "../ui/styled";
import ScoreWrapper from "../summoner/DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";


const AbsoluteDiv = styled.div`
  position: absolute;
  top: 72px;
  left: 17px;
`;
const DuoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 55px;
`;


const LoadingWrapper = styled.div`
  position: absolute;
  top: 20px;

`;

const TagWrapper = styled.div`
  position: absolute;
  left: 16px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const DictWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 66px;
  white-space: nowrap;
`;

const BlankDiv = styled.div`
  min-width: 66px;
  height: 37px;
`;

const AIScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InfluWrapper = styled.div`
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;

  .influ-name {
    line-height: 14px;
  }
`;

const NameBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const TagText = styled.span`
  color: ${props => props.theme.color.dark_200};
  margin-left: 3px;
`;

const AIScoreBox = styled.div`
  margin-top: 15px;
`;
const GamesCount = styled.span`
  color: ${props => props.theme.color.dark_100};
`;
const MultiItem = ({
                       isDuo,
                       multiItem,
                       isLoading,
                       openId,
                       handleLayer,
                       ranks,
                       renderType,
                       typeButton,
                       setTypeButton
                   }) => {
    const {t} = useTranslation();
    const region = useSelector((state) => state.region.result);
    const currentLang = useSelector((state) => state.translation.lang);
    const theme = useTheme();


    const getCount = (array, flag) => {
        try {
            const copyArray = [...array];
            const result = [];

            for (let i in copyArray) {
                const findIndex = result.findIndex((data, index) => data[flag] === copyArray[i][flag]);
                if (findIndex > -1) {
                    result[findIndex] = {
                        ...result[findIndex],
                        gameCount: result[findIndex].gameCount + 1,
                        ai_score: result[findIndex].ai_score + copyArray[i].ai_score,
                        wins: copyArray[i].win ? result[findIndex].wins + 1 : 0,
                    }
                } else {
                    result.push({
                        gameCount: 1,
                        [flag]: copyArray[i][flag],
                        ai_score: copyArray[i].ai_score,
                        wins: copyArray[i].win ? 1 : 0,
                    });
                }
            }

            result.sort((a, b) => {
                if (a.gameCount < b.gameCount) {
                    return 1;
                } else if (a.gameCount > b.gameCount) {
                    return -1;
                } else if (a.gameCount === b.gameCount) {

                    const aScore = a.ai_score / a.gameCount;
                    const bScore = b.ai_score / b.gameCount;

                    if (a.wins < b.wins) {
                        return 1;
                    } else if (a.wins < b.wins) {
                        return -1;
                    } else if (a.wins === b.wins) {
                        if (aScore < bScore) {
                            return 1;
                        } else if (aScore > bScore) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                }
            });

            return result;
        } catch (e) {
            return "Top";
        }
    }

    // 소환사 하나씩 데이터 받아오기
    // const getSummonerData = useCallback(() => {
    //     let name = multiItem.summoner.summoner_basic_info_dict.riot_id_name;
    //     // 공백제거
    //     if (name.indexOf(' ') !== -1) {
    //         const result = name.replace(' ', '%20');
    //         name = result;
    //     }
    //
    //     axios.get(
    //             `${process.env.REACT_APP_API}get_multisearch?summonerName=${encodeURI(
    //                 name
    //             )}&season=13&region=${region}`
    //         )
    //         .then((res) => {
    //             const result = res.data.result.a;
    //
    //             if (result === undefined) return;
    //
    //             setmultiItem(result);
    //             setNewItem(result);
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // }, [multiItem.summoner.summoner_basic_info_dict.riot_id_name, region, setNewItem]);

    // 소환사 업데이트
    // const getSummonerUpdate = useCallback(() => {
    //     // 소켓 도메인/네임스페이스
    //     const namespace = `${process.env.REACT_APP_API}update_match_multi`;
    //     // 소켓 연결
    //     const socket = socketio.connect(
    //         namespace,
    //         {
    //             transports: ['websocket'],
    //             upgrade: false,
    //             secure: true,
    //         },
    //         {forceNew: true}
    //     );
    //     // 업데이트 연결 시 User Connected emit
    //     socket.on('connect', () => {
    //         socket.emit('my event multi', {
    //             data: 'User Connected',
    //         });
    //
    //         socket.emit('my event multi', {
    //             summoner_id: multiItem.summoner.summoner_basic_info_dict.summoner_id,
    //             region: region,
    //             season: 13,
    //             count: 0,
    //         });
    //     });
    //     // 서버에서 데이터 받는 경우
    //     socket.on('my response multi', (msg) => {
    //         // 카운트
    //         if (msg.finish_num !== undefined) {
    //             let per = Math.floor((msg.finish_num / msg.match_len) * 100);
    //             setUpdated(per);
    //         }
    //         // finish가 100이면(서버에서 종료 요청) 소켓 disconnect
    //         if (msg.finish === 100 ) {
    //             socket.disconnect();
    //             setUpdated(100);
    //             if(msg.match_len > 0) {
    //                 getSummonerData();
    //             }
    //         }
    //
    //         // 받은 데이터에 summoner_id 값이 있으면, 다시 서버에 진행정도 확인 요청
    //         if (msg.hasOwnProperty('summoner_id')) {
    //             socket.emit('my event multi', {
    //                 summoner_id: msg.summoner_id,
    //                 summoner_key: msg.summoner_key,
    //                 region: msg.region,
    //                 match_len: msg.match_len,
    //                 match_len: msg.match_len,
    //                 finish_check: 100,
    //                 count: msg.count,
    //             });
    //         }
    //     });
    // }, [getSummonerData,multiItem, region]);
    //
    // useEffect(() => {
    //     getSummonerUpdate();
    // }, [getSummonerUpdate]);


    return (
        <>
            <div className={`row complete`}>
                <TagWrapper>
                    <DictWrapper>
                        <SummonerDict
                            onlyLatest={true}
                            summoner={multiItem}
                            dict={multiItem?.summoner?.summoner_basic_info_dict?.previous_season_tier_list}
                        />
                    </DictWrapper>
                    <SummonerTags
                        ranks={ranks}
                        data={multiItem}
                    />
                </TagWrapper>

                <div className="col flex_column1">
                    <BlankDiv/>
                    <div style={{display: 'flex', flexDirection: 'row', width: "100%"}}>
                        <div className="icons">
                            <div>
                            </div>
                            {(multiItem?.summoner?.season_tier_info_dict?.ranked_solo_5x5?.hasOwnProperty('tier') && multiItem.summoner.season_tier_info_dict.ranked_solo_5x5.tier) ? (
                                <img
                                    src={`/images/Emblem_${multiItem.summoner.season_tier_info_dict.ranked_solo_5x5.tier}.png`}
                                    className="imgEmblem"
                                    width={50}
                                    height={50}
                                    alt={"???"}
                                />
                            ) : (
                                <img src="/images/Emblem_UNRANKED.png"
                                     width={50}
                                     height={50}
                                     className="imgEmblem" alt="UNRANKED"/>
                            )}
                            <AbsoluteDiv>
                                <DuoWrapper>
                                    {(Array.isArray(isDuo) && isDuo.length > 0) && isDuo.map((data) => {
                                        return (<SummonerTag
                                            background={data.background}
                                            borderColor={data.borderColor}
                                            textColor={data.textColor}
                                        >DUO</SummonerTag>)
                                    })}
                                </DuoWrapper>
                            </AbsoluteDiv>
                            {multiItem?.summoner_info_dict.last_match_list?.length > 0 &&
                                <p>
                                    <ImageLane
                                        color={'dark'}
                                        lane={getCount(multiItem.summoner_info_dict.last_match_list, 'position')[0].position}/>
                                    <span>{cutLaneName(getCount(multiItem.summoner_info_dict.last_match_list, 'position')[0].position)}</span>
                                </p>
                            }
                        </div>
                        <div
                            className={`texts ${
                                Object.values(multiItem.summoner_info_dict.tag).find((data) => data > 0)
                                    ? 'tag_on'
                                    : 'tag_off'
                            }`}
                        >
                            {multiItem?.summoner?.summoner_basic_info_dict?.pro_streamer_info_dict?.status !== '-' &&
                                <InfluWrapper>
                                    <InfluInfo
                                        maxWidthText={80}
                                        showLinks={false}
                                        influData={multiItem?.summoner.summoner_basic_info_dict.pro_streamer_info_dict}
                                    />
                                </InfluWrapper>
                            }

                            {/*                {multiItem.summoner_info.pro_streamer_info.team !== '-' && (*/}
                            {/*                    <span>*/}
                            {/*  {multiItem.summoner_info.pro_streamer_info.team} {multiItem.summoner_info.pro_streamer_info.name}*/}
                            {/*</span>*/}
                            {/*                )}*/}
                            <Link
                                to={{
                                    pathname: `/summoner/${region}/${multiItem.summoner.summoner_basic_info_dict.riot_id_name}-${multiItem.summoner.summoner_basic_info_dict.riot_id_tag_line}`,

                                }}
                                target="_blank"
                                className="common_search"
                            >
                                <NameBox>
                                    <span>{multiItem.summoner.summoner_basic_info_dict.riot_id_name}</span>
                                    <TagText>#{multiItem.summoner.summoner_basic_info_dict.riot_id_tag_line}</TagText>
                                </NameBox>

                            </Link>
                            <dl className="t1">
                                <dt>
                                    {tierText(
                                        multiItem?.summoner?.season_tier_info_dict?.ranked_solo_5x5?.tier,
                                        multiItem?.summoner?.season_tier_info_dict?.ranked_solo_5x5?.division
                                    )}
                                    /
                                </dt>
                                <dd style={{flexDirection: 'row'}}>{multiItem?.summoner?.season_tier_info_dict?.ranked_solo_5x5?.league_points} LP</dd>
                            </dl>
                            <dl className="t2">
                                {Object.values(multiItem?.summoner?.season_tier_info_dict?.ranked_solo_5x5).length !== 0 ? (
                                    <dt>
                <span>
                  {multiItem?.summoner?.season_tier_info_dict?.ranked_solo_5x5?.wins !== 0
                      ? (
                          (multiItem.summoner.season_tier_info_dict.ranked_solo_5x5.wins /
                              (multiItem.summoner.season_tier_info_dict.ranked_solo_5x5.wins +
                                  multiItem.summoner.season_tier_info_dict.ranked_solo_5x5.losses)) *
                          100
                      ).toFixed(1)
                      : 0}
                </span>
                                        %
                                    </dt>
                                ) : (
                                    <dt>
                                        <span>-</span>%
                                    </dt>
                                )}
                                /
                                {Object.values(multiItem.summoner.season_tier_info_dict.ranked_solo_5x5).length !== 0 ? (
                                    <dd>
                                        <GamesCount>
                                            {multiItem.summoner.season_tier_info_dict.ranked_solo_5x5.wins +
                                                multiItem.summoner.season_tier_info_dict.ranked_solo_5x5.losses}
                                        </GamesCount>
                                        {t('summoner.games')}
                                    </dd>
                                ) : (
                                    <dd>
                                        <span>-</span>
                                        {t('summoner.games')}
                                    </dd>
                                )}
                            </dl>
                        </div>
                    </div>
                </div>
                <div className="col flex_column2">

                    {isLoading &&
                        <LoadingWrapper>
                            <Loading size={'4px'}/>
                        </LoadingWrapper>
                    }
                    <div className="aiscore">
                        <FlexBox height={'24px'} justifyContent={'space-between'} width={'100%'}>
                            <p>
                                {multiItem.summoner_info_dict.wins}
                                {t('summoner.contractionWin')}
                            </p>

                            <DifferentScoreColor value={multiItem.summoner_info_dict.ai_score_avg_win}/>
                        </FlexBox>
                        <FlexBox height={'24px'} justifyContent={'space-between'} width={'100%'}>
                            <p>
                                {multiItem.summoner_info_dict.losses}
                                {t('summoner.contractionLose')}
                            </p>
                            <DifferentScoreColor value={multiItem.summoner_info_dict.ai_score_avg_loss}/>
                        </FlexBox>
                        <AIScoreBox>
                            <ScoreWrapper
                                fontSize={20}
                                paddingHorizontal={10}
                                paddingVertical={5}
                                score={multiItem.summoner_info_dict.ai_score_avg}

                            />
                        </AIScoreBox>
                        {/*<strong>{scoreColor(multiItem.summoner_info_dict.ai_score_avg)}</strong>*/}
                    </div>
                </div>
                <div className="col flex_column3">
                    {multiItem.summoner_info_dict.last_match_list.length !== 0 ? (
                        <ul className="match_list">
                            {multiItem.summoner_info_dict.last_match_list.map((item, index) => (
                                <li key={item.timestamp} className="match_item">
                                    <div className={`box ${item.is_win ? 'win' : 'lose'}`}>
                                        <div className="champ">
                                            <ChampionImageWithLane champion_id={item.champion_id}
                                                                   position={item.position}/>
                                            {/*<ImageLane lane={item.position} color="white"/>*/}
                                        </div>
                                        <AIScoreWrapper>
                                            <MultiItemScore
                                                setTypeButton={setTypeButton}
                                                typeButton={typeButton}
                                                target={renderType}
                                                item={item}
                                                t={t}
                                            />
                                        </AIScoreWrapper>

                                    </div>
                                    <div className="time">{getShortTime(item.end_timestamp, currentLang)}</div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="no_list">최근 게임 기록이 없습니다.</div>
                    )}
                </div>
                <div className="col flex_column4"
                     onClick={() => handleLayer(multiItem.summoner.summoner_basic_info_dict.summoner_id)}>
                    {multiItem?.summoner_info_dict?.last_match_list?.length !== 0 ? (
                        <div
                            className={`float ${openId === multiItem.summoner.summoner_basic_info_dict.summoner_id ? 'open' : 'close'}`}>
                            <ul className="champ_list">
                                {multiItem.season_champion_info_list.map((item, index) => {
                                    return (
                                        <li key={index} className="champ_item">
                                            <div className="champ">
                                                <ChampionImageWithLane champion_id={item.champion_id}/>
                                            </div>
                                            <div className="games">
                                                <span>{item.games}</span>
                                            </div>
                                            <div className="winrate">{scoreColor(Math.trunc(item.win_rate))}%</div>
                                            <div className="kda">{kdaColor(item.kda.toFixed(2))} KDA
                                            </div>
                                            {/*<div className="score">{scoreColor(item.ai_score)}</div>*/}
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="more">
                                <button
                                >
                                    <img
                                        className={openId === multiItem.summoner.summoner_basic_info_dict.summoner_id ? 'open' : 'close'}
                                        src={`${theme.icon.path}/icn_expand_open.svg`}
                                        alt="열기/닫기"
                                    />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="no_list">최근 게임 기록이 없습니다.</div>
                    )}
                </div>
            </div>
        </>
    )
        ;
};

export default MultiItem;
