import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import TableARAMFilter from "./TableARAMFilter";
import {allOffObject, changeListBySort, getNestedObject} from "../../../../function";
import {useTranslation} from "react-i18next";
import TableARAM from "./TableARAMFilter/TableARAM";
import {ErrorBoundary} from "react-error-boundary";

const Wrapper = styled.div`
  background: ${props => props.theme.color.site_main_color};
`;

const FilterWrapper = styled.div`
  padding: 10px 10px 10px 20px;
`;

const EmptyDiv = styled.div`
  width: 100%;
  height: 492px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const ChampRenderTableARAM = ({seasonList, selectedSeason, onChangeSelectedSeason, champCategory, currentCategory,summonerARAM}) => {
    const currentLang = useSelector((state) => state.translation.lang);
    const {t} = useTranslation();
    const season = useSelector((state) => state.imageStore.aramSeason);
    const champions = useSelector((state) => state.imageStore.champions);
    const [currentList, setCurrentList] = useState(getNestedObject(`${champCategory[currentCategory].value}.total.enemy_champion_stats.All`, summonerARAM));
    const mostDamage = useMemo(() => {
        if(summonerARAM) {
            const nested = getNestedObject(`${champCategory[currentCategory].value}.total.enemy_champion_stats.All`, summonerARAM);
            return getMinMaxValue(nested);
        }
        return {
            most : 0,
            least: 0,
        }
    },[summonerARAM]);
    const initialSort = [
        {
            value: 'games',
            sortType: false,
        },
        {
            value: 'win_rate',
            sortType: false,
        },
        {
            value: 'champion_name',
            sortType: true,
        },
    ];

    const [sort, setSort] = useState({
        ...initialSort,
    });

    const handleInput = useCallback((value) => {
        if (typeof value === 'string' && value) {

            const result = getNestedObject(`${champCategory[currentCategory].value}.total.enemy_champion_stats.All`, summonerARAM);
            const regexp = new RegExp(`${value}`);

            if (result.length > 0) {

                setCurrentList(result.filter((data) => {
                    const findChamp = champions.find((champ) => Number(champ.champion_id) === Number(data.champion_id));
                    if (findChamp) {
                        return (regexp.test(findChamp.champion_name) || regexp.test(findChamp.champion_name_en))
                    } else {
                        return false;
                    }

                }));

            }
        } else {
            handleReset();
        }
    }, [currentCategory, summonerARAM, champCategory]);

    const [sortOrder, setSortOrder] = useState({
        games: {
            value: 'games',
            sortType: false,
        },
        win_rate: {
            value: 'win_rate',
            sortType: true,
        },
        champion_name_kr: {
            value : "champion_name_kr",
            sortType: true,
        },
        champion_name_en: {
            value : "champion_name_en",
            sortType: true,
        },
        champion_name : {
           value: "champion_name",
            sortType:true,
        },
        kda: {
            value: 'kda',
            sortType: true,
        },
        cs_per_min: {
            value: 'cs_per_min',
            sortType: true,
        },
        damage_dealt_per_min: {
            value: 'damage_dealt_per_min',
            sortType: true,
        },
        damage_taken_per_min: {
            value: 'damage_taken_per_min',
            sortType: true,
        },
        damage_per_team: {
            value: "damage_per_team",
            sortType: true,
        },
        penta_kills : {
            value: "penta_kills",
            sortType: true,
        },
        quadra_kills : {
            value: "quadra_kills",
            sortType: true,
        },
        triple_kills : {
            value: "triple_kills",
            sortType: true,
        },
        double_kills : {
            value: "double_kills",
            sortType: true,
        }
    });

    const handleReset = useCallback(() => {
        const value = getNestedObject(`${champCategory[currentCategory].value}.total.enemy_champion_stats.All`, summonerARAM);
        setCurrentList(changeListBySort(value, [{value: 'games', sortType: false}, {
            value: "wins",
            sortType: false
        }, {
            value: 'champion_name', sortType: false
        }],champions));
    }, [currentCategory]);



    function getMinMaxValue(array) {
        if (Array.isArray(array) && array.length > 0) {
            const result = array.reduce((sum, cur) => {
                const mostValue = Number(cur.damage_dealt_per_min);
                const leastValue = Number(cur.damage_dealt_per_min);
                sum.most = Math.max(sum.most, Number(mostValue))
                sum.least = Math.max(sum.least, Number(leastValue))
                return sum;
            }, {
                most: 0,
                least: 0
            });
            return result;
        } else {
            return {most: 0, least: 0}

        }
    }





    const handleSort = useCallback((data) => {

        if (typeof data === 'object') {
            const copyData = {...data};
            const copySortData = {...sortOrder};

            if (copyData) {
                if (copySortData[copyData.value]) {
                    const allOff = allOffObject(copySortData);
                    copyData.sortType = !copyData.sortType;
                    allOff[copyData.value].sortType = copyData.sortType;
                    setSort([{...copyData}]);
                    setSortOrder(copySortData);

                    setCurrentList((prev) => changeListBySort(prev, [{...copyData},{value: 'wins', sortType: false},{
                        value: 'champion_name', sortType: false
                    }],champions))
                }
            }
        }
    }, [sortOrder]);

    useEffect(() => {
        handleReset();
    },[summonerARAM,currentLang]);



    return (
        <Wrapper>
            <FilterWrapper>
                <TableARAMFilter
                    handleReset={handleReset}
                    onChangeInput={handleInput}
                    onChangeSelectedSeason={onChangeSelectedSeason}
                    selectedSeason={selectedSeason}
                    seasonList={seasonList}
                    season={season}
                />
            </FilterWrapper>
                <ErrorBoundary FallbackComponent={() => <EmptyDiv>{t('notice.noData')}</EmptyDiv>}>
                    {Array.isArray(currentList) && currentList.length < 1 ?
                        <EmptyDiv>{t('notice.noData')}</EmptyDiv>
                        :
                        <TableARAM
                            sort={sort}
                            mostDamage={mostDamage.most}
                            leastDamage={mostDamage.least}
                            initialSort={initialSort}
                            sortOrder={sortOrder}
                            setSortOrder={setSortOrder}
                            data={currentList}
                            handleSort={handleSort}/>
                    }

                </ErrorBoundary>
        </Wrapper>
    );
};

export default ChampRenderTableARAM;
