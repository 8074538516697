import React from 'react';
import styled from "styled-components";
import {FlexBox} from "../../../../../ui/styled";
import SmallInfluInfo from "../../../../../common/SmallInfluInfo";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const ImageWrapper = styled.div`
  width: 28px;
  border-radius: 50%;
  aspect-ratio: 1;
  border: 1px solid black;
  background-color: black;

  margin-right: 10px;
`;

const ProfileIcon = styled.img`
  z-index: 999;
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;

const SummonerName = styled.span`
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 3px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ErrorBox = styled.div`
  margin-left: 6px;
`;

const StreakBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-right: 12px;
`;

const StreakSpan = styled.span`
  font-size: 14px;
  color: ${props => {
    let color = '';
    if (props.streak === 'winning') color = '#FF6060';
    if (props.streak === 'losing') color = '#538FE8';
    return color;
  }};
`;

const TagName = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-size: 14px;
  margin-left: 2px;
`;


const ItemText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
  margin-left: 4px;
  cursor: pointer;
  padding-bottom: 2px;

  &:hover {
    text-decoration: underline;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: 0;
`;


const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  unicode-bidi: isolate;
`;

const RankingSummoner = ({
                             iconId,
                             version,
                             summonerName,
                             tag,
                             status,
                             proName,
                             onClickSummoner,
                             onHover,
                             proTeam,
                             teamLogo
                         }) => {


    return (
        <Wrapper>
            <ProfileImage iconId={iconId} version={version}/>
            <NameWrapper>
                {status ?
                    <TextWrapper>
                        {onHover ?
                            <TextWrapper>
                                <ItemText onClick={onClickSummoner}>{summonerName} </ItemText>
                                <TagName>#{tag}</TagName>
                            </TextWrapper>
                            :
                            <TextWrapper>
                                <FlexBox>
                                    <SmallInfluInfo status={status}/>
                                </FlexBox>
                                <ItemText>{proTeam} {proName}</ItemText>
                            </TextWrapper>
                        }
                    </TextWrapper>
                    :
                    <TextWrapper>
                        <ItemText onClick={onClickSummoner}>{summonerName} </ItemText>
                        <TagName>#{tag}</TagName>
                    </TextWrapper>}
            </NameWrapper>
        </Wrapper>
    );
};


const ProfileImage = ({version, iconId,}) => {


    return (
        <ImageWrapper>
            <ProfileIcon
                src={`${process.env.REACT_APP_IMG}${version}/img/profileicon/${iconId}.png?w=56&h=56&f=webp`}
                alt={''} onError={(e) => e.target.src = "/images/icon_non_item.svg"}/>
        </ImageWrapper>
    )

}

export default RankingSummoner;
